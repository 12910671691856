import { Permission } from '../enums/permission.enum';

export interface AuthenticationModel {
  access_token: string;
  expires_in: number;  
  token_type: string;
  refresh_token: string;
  scope: string;
  isExpired?: boolean;
  expirationDate: Date;  
  decodedToken: string;
  claims: string[];
  userRole?: [Permission];
  userFullName: string;
}

export const EmptyAuthentication: AuthenticationModel = {
  access_token: "",
  expires_in: 0,
  token_type: "",
  refresh_token: "",
  scope: "", 
  isExpired: null,
  expirationDate: null,
  decodedToken: "",
  claims: null,
  userFullName: ""
};
