import { AuthenticationModel } from "src/app/core/interfaces/authentication.model";
import { User } from "src/app/core/interfaces/user.interface";

export class UsersStateModel {
  auth: AuthenticationModel;
  user: User;
  loaded: boolean;
  loading: boolean;
  errors: string;
  employerForTimekeeper: number;
  requestMFA: boolean; 
}