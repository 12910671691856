import { Location } from "@angular/common";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { ResetTimeheetsState } from "src/app/features/timesheets/states/timesheets/timesheets.actions";
import { AuthenticationModel, EmptyAuthentication } from "../../../core/interfaces/authentication.model";
import { UsersService } from "../services/users.service";
import { BackToLogin, Login, Logout, PreLogin, RefreshToken, SaveEmployerForTimekeeper, SendCode, SetAuthModel, SetClaims, SetEmployerForTimekeeper, SetRoles, UpdateUserFullName, ValidateCode } from "./users.actions";
import { UsersStateModel } from "./users.model";

const AUTH_LOCALSTORAGE_CONST = "authModel";
const EMPLOYER_LOCALSTORAGE_CONST = "employerForTimekeeper";

@State<UsersStateModel>({
  name: "users",
  defaults: {
    auth: EmptyAuthentication,
    user: null,
    loaded: false,
    loading: false,
    errors: "",
    employerForTimekeeper: null,
    requestMFA: false
  }
})
@Injectable()
export class UsersState implements NgxsOnInit {
  constructor(
    private usersService: UsersService,
    private localStorageService: LocalStorageService,
    private env: EnvironmentService,
    private router: Router,
    private location: Location,
    private ngZone: NgZone
  ) {}

  ngxsOnInit(ctx: StateContext<UsersStateModel>) {
    // once the app starts, i check if there is a user loged in the localstorage and add it to the state
    if (this.env.isBrowser) {
      const authModel = this.localStorageService.get(AUTH_LOCALSTORAGE_CONST);
      if (authModel != null) {
        ctx.dispatch(new SetAuthModel(authModel));
      }
      const employerTimekeeper = this.localStorageService.get(EMPLOYER_LOCALSTORAGE_CONST);
      if (employerTimekeeper != null) {
        ctx.dispatch(new SetEmployerForTimekeeper(employerTimekeeper));
      }
    }
  }

  @Selector() static auth(state: UsersStateModel) { return state.auth; }
  @Selector() static userRole(state: UsersStateModel) { return state.auth?.userRole || []; }
  @Selector() static user(state: UsersStateModel) { return state.user; }
  @Selector() static loaded(state: UsersStateModel) { return state.loaded; }
  @Selector() static loading(state: UsersStateModel) { return state.loading; }
  @Selector() static requestMFA(state: UsersStateModel) { return state.requestMFA; }
  @Selector() static errors(state: UsersStateModel) { return state.errors; }
  @Selector() static employerForTimekeeper(state: UsersStateModel) { return state.employerForTimekeeper; }

  @Action(PreLogin)
  preLogin(ctx: StateContext<UsersStateModel>, action: PreLogin) {
    ctx.patchState({
      loading: true
    });

    return this.usersService.preLogin(action.username, action.password).pipe(
      tap(
        (response) => {
          this.ineedit = null;
          if (response.mfaEnabled) {
            this.ineedit = `${btoa(action.username)}-${btoa(action.password)}`;
            ctx.dispatch(new SendCode(action.username));
            ctx.patchState({
              requestMFA: true
            });
          } else {
            ctx.dispatch(new Login(action.username, action.password, false));
          }
        },
        (error) => {
          ctx.patchState({
            loading: false,
            errors: error
          });
        }
      )
    );
  }

  ineedit;
  @Action(Login)
  login(ctx: StateContext<UsersStateModel>, action: Login) {
    ctx.patchState({
      loading: true
    });

    return this.usersService.login(action.username, action.password, action.isUserSwitch, action.verificationCode).pipe(
      tap(
        (response) => {
          const authModel = this.createAuthModel(response);
          ctx.patchState({
            auth: authModel,
            loaded: true,
            loading: false
          });
            
          this.localStorageService?.set(AUTH_LOCALSTORAGE_CONST, authModel);

          this.ngZone.run(() => {
            this.usersService.redirectAfterLogin(authModel.userRole[0]);
            if (action.isUserSwitch) {
              this.localStorageService?.remove(EMPLOYER_LOCALSTORAGE_CONST);

              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          });
        },
        (error) => {
          ctx.patchState({
            loading: false,
            errors: error?.error?.error
          });
        }
      )
    );
  }

  @Action(SendCode)
  sendCode({ patchState }: StateContext<UsersStateModel>, action: SendCode) {
    patchState({
      loading: true
    });

    return this.usersService.sendCode(action.username).pipe(
      tap(
        () => {
          patchState({
            loading: false
          });
        },
        (error) => {
          patchState({
            loading: false
          });
        }
      )
    );
  }

  @Action(ValidateCode)
  validateCode(ctx: StateContext<UsersStateModel>, action: ValidateCode) {
    ctx.patchState({
      loading: true
    });

    const user = this.ineedit.split('-');
    const username = atob(user[0]);
    const password = atob(user[1]);
    ctx.dispatch(new Login(username, password, false, action.code));
  }

  @Action(SetAuthModel)
  setAuthModel({ patchState }: StateContext<UsersStateModel>, action: SetAuthModel) {
    patchState({
      auth: action.authModel,
      loaded: true,
      loading: false
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<UsersStateModel>) {
    if (this.env.isBrowser) {
      this.localStorageService.remove(AUTH_LOCALSTORAGE_CONST);
      this.localStorageService.remove(EMPLOYER_LOCALSTORAGE_CONST);
      this.location.replaceState('/users/login');
      window.location.reload();
    }
  }

  @Action(RefreshToken)
  refreshToken(ctx: StateContext<UsersStateModel>) {
    ctx.patchState({
      loading: true
    });
    const state = ctx.getState();
    return this.usersService.refreshToken(state.auth.refresh_token).pipe(
      tap(
        res => {
          const authModel = this.createAuthModel(res);
          ctx.patchState({
            auth: authModel,
            loaded: true,
            loading: false
          });
          if (this.env.isBrowser) this.localStorageService.set(AUTH_LOCALSTORAGE_CONST, authModel);
        },
        err => {
          ctx.patchState({
            loading: false,
            loaded: false,
            errors: err
          });
          ctx.dispatch(new Logout());
        }
      )
    );
  }

  createAuthModel(res: any): AuthenticationModel {
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(res.access_token);
    let claims: string[] = decodeToken["User/GrantedAccess"];
    if (typeof claims === "string") claims = [claims];
    claims = claims.concat(decodeToken["User/Roles"]);

    const authModel: AuthenticationModel = {
      ...res,
      decodedToken: decodeToken,
      expirationDate: helper.getTokenExpirationDate(res.access_token),
      isExpired: helper.isTokenExpired(res.access_token),
      claims: claims,
      userRole: Array.isArray(decodeToken["User/Roles"])
        ? decodeToken["User/Roles"]
        : [decodeToken["User/Roles"]],
      userFullName: decodeToken["User/FullName"]
    };
    return authModel;
  }

  @Action(SaveEmployerForTimekeeper)
  saveEmployerForTimekeeper(ctx: StateContext<UsersStateModel>, { employerId }) {
    ctx.patchState({
      employerForTimekeeper: employerId
    });
    this.localStorageService?.set(EMPLOYER_LOCALSTORAGE_CONST, employerId);
    ctx.dispatch(new ResetTimeheetsState());
    this.ngZone.run(() => {
      this.router.navigate(["/timekeeping"]);
    });
  }

  @Action(SetEmployerForTimekeeper)
  setEmployerForTimekeeper({ patchState }: StateContext<UsersStateModel>, { employerId }) {
    patchState({
      employerForTimekeeper: employerId
    });
  }

  @Action(UpdateUserFullName)
  updateUserFullName(ctx: StateContext<UsersStateModel>, action: UpdateUserFullName) {
    const state: UsersStateModel = ctx.getState();
    ctx.patchState({
      auth: {
        ...state.auth,
        userFullName: action.fullname
      }
    });
  }

  @Action(BackToLogin)
  backToLogin({ patchState }: StateContext<UsersStateModel>) {
    patchState({
      requestMFA: false,
      loading: false,
      errors: null
    });
  }

  @Action(SetClaims)
  // Used for testings only
  setClaims(ctx: StateContext<UsersStateModel>, action: SetClaims) {
    ctx.patchState({
      auth: {
        ...ctx.getState().auth,
        claims: action.claims
      }
    });
  }

  @Action(SetRoles)
  // Used for testings only
  setRoles(ctx: StateContext<UsersStateModel>, action: SetRoles) {
    ctx.patchState({
      auth: {
        ...ctx.getState().auth,
        userRole: action.roles
      }
    });
  }
}
