import { Permission } from 'src/app/core/enums/permission.enum';
import { AuthenticationModel } from '../../../core/interfaces/authentication.model';

export class PreLogin {
  public static readonly type = "[Users] PreLogin";
  constructor(
    public username: string,
    public password: string
  ) {}
}

export class Login {
  public static readonly type = "[Users] Login";
  constructor(
    public username: string,
    public password: string,
    public isUserSwitch: boolean,
    public verificationCode?: string
  ) {}
}

export class SendCode {
  public static readonly type = "[Users] SendCode";
  constructor(
    public username: string
  ) {}
}

export class ValidateCode {
  public static readonly type = "[Users] ValidateCode";
  constructor(
    public readonly code: string
  ) {}
}

export class Logout {
  public static readonly type = "[Users] Logout";  
}

export class SetAuthModel {
  public static readonly type = "[Users] SetAuthModel";
  constructor(public authModel: AuthenticationModel) {}
}

export class RefreshToken {
  public static readonly type = "[Users] RefreshToken";
  constructor() {}
}

export class SaveEmployerForTimekeeper {
  public static readonly type = "[Users] SaveEmployerForTimekeeper";
  constructor(public employerId: number) {}
}

export class SetEmployerForTimekeeper {
  public static readonly type = "[Users] SetEmployerForTimekeeper";
  constructor(public employerId: number) {}
}

export class BackToLogin {
  public static readonly type = "[Users] BackToLogin";
}

export class SetClaims {
  // Used for testings only
  public static readonly type = "[Users] set claims";
  constructor(public claims: Permission[]) {}
}

export class SetRoles {
  // Used for testings only
  public static readonly type = "[Users] set roles";
  constructor(public roles: [Permission]) {}
}

export class UpdateUserFullName {
  public static readonly type = "[Users] update user full name";
  constructor(public readonly fullname: string) {}
}